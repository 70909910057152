@use "sass:map";
@import '../../styles/custom';

@keyframes cookie-consent-banner-animation-show {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}

@keyframes cookie-consent-banner-animation-unshow {
  from {
    bottom: 0;
  }
  to {
    bottom: -100px;
  }
}

.cookie-consent-banner {
  width: 100%;
  border: 1px solid $gray-300;
  border-radius: 6px 6px 0 0;
  background-color: $white;
  padding: 1.5em;
  z-index: 1060;

  position: fixed;
  bottom: 0;

  &--show {
    animation-name: cookie-consent-banner-animation-show;
    animation-duration: 0.5s;
  }

  &--unshow {
    animation-name: cookie-consent-banner-animation-unshow;
    animation-duration: 0.5s;
  }

  &__button {
    border-radius: 8px;
    padding: 8px 20px;
    margin: 8px 0;
  }

  @media screen and (max-width: map.get($grid-breakpoints,'xl')) {
    &__backdrop {
      top: 0;
      left: 0;
      z-index: 1060;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
